<template>
  <div class="container">
    <van-cell-group>
      <van-cell title="五合校区" label="广西南宁市青秀区五合大道19号" />
      <van-cell
        title="空港校区"
        label="广西扶绥县中国-东盟南宁空港经济区空港大道东89号"
      />
      <van-cell title="邮政编码" label="530222" />
      <van-cell
        title="联系电话"
        label="0771-4797113、4797229、4797126、4797128、4797228"
      />
      <van-cell title="官方网址" label="https://www.gxufl.com" />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.container {
  padding: 10px;
  font-size: 14px;
}
</style>